<template>
  <section class='ipo-table-container'>
    <table class='ipo-table border-l border-r'>
      <thead class='sticky top-0 bg-white'>
        <tr>
          <th class='border-b border-t' rowspan='2'>
            <div class='flex flex-row justify-between items-center'>
              <span>
                종목명
              </span>
              <label class='flex flex-row justify-start items-center gap-x-1 whitespace-no-wrap'>
                <input type='checkbox'
                  class='form-checkbox'
                  name='exceptSpac'
                  v-model='exceptSpac'>
                <span class='text-xs'>스팩 제외</span>
              </label>
            </div>
          </th>
          <th class='border-b border-l border-t' rowspan='2'>주간사</th>
          <th class='border-b border-l border-t' rowspan='2'>시장구분</th>
          <th class='border-b border-l border-t' rowspan='2'>섹터</th>
          <th class='border-b border-l border-t text-center' colspan='4'>수요예측정보</th>
          <th class='border-b border-l border-t text-center' colspan='4'>주식수</th>
          <th class='border-b border-l border-t text-center' colspan='7'>수요예측결과</th>
        </tr>
        <tr class='text-left'>
          <th class='border-b border-l'>수요예측일</th>
          <th class='border-b border-l text-right'>상장일</th>
          <th class='border-b border-l text-right'>희망공모가액</th>
          <th class='border-b border-l text-right'>희망공모시총</th>
          <th class='border-b border-l text-right'>공모금액</th>
          <th class='border-b border-l text-right'>총공모주식수</th>
          <th class='border-b border-l text-right'>유통가능주식수</th>
          <th class='border-b border-l text-right'>총발행주식수</th>
          <th class='border-b border-l text-right'>유통가능비율</th>
          <th class='border-b border-l text-right'>수요예측참여가</th>
          <th class='border-b border-l text-right'>확정공모가</th>
          <th class='border-b border-l text-right'>확정공모시총</th>
          <th class='border-b border-l text-right'>기관경쟁률</th>
          <th class='border-b border-l text-right'>의무보유확약</th>
          <th class='border-b border-l text-right'>청약경쟁률</th>
        </tr>
      </thead>
      <tbody v-for='ipo in filteredData' :key='ipo.id'>
        <ipo-information-row :ipo='ipo'
          :style='selectedRow(ipo.id)'
          @select-row='selectRow' />
      </tbody>
    </table>
  </section>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import IpoInformationRow from '@/views/ipo/IpoInformationRow.vue'

export default {
  name: 'IpoInformation',
  components: {
    IpoInformationRow,
  },
  data () {
    return {
      selectedIpoId: 0,
      exceptSpac: true,
    }
  },
  computed: {
    ...mapFields('ipos', [
      'ipos',
    ]),
    filteredData () {
      return this.exceptSpac ? this.ipos.filter(ipo => !(ipo.name.includes('스팩') || ipo.name.includes('기업인수'))) : this.ipos
    },
  },
  methods: {
    selectedRow (ipoId) {
      return (this.selectedIpoId == ipoId) ? 'background-color: lightyellow' : ''
    },
    selectRow (ipoId) {
      this.selectedIpoId = ipoId
    },
  }
}
</script>

<style lang='scss' scoped>

  table {
    border-collapse: separate;
  }
  .ipo-table-container {
    @apply overflow-auto;
    width: 100%;
    height: calc(100vh - 9rem);
  }

  @media (min-width: 1024px) {
    .ipo-table-container {
      height: calc(100vh - 11rem);
    }
  }

</style>
